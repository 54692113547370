/* * Global */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Bitter', serif;
  background-color: #fff;
  min-height: 100vh;
  color: #000;
  font-size: 18px;
  line-height: 2em;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}

img {
  width: 100%;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #008000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  cursor: default;
  margin-top: 0;
}

/* * Wrapper */

.wrapper {
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1230px;
}

/* * Icon */

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}
